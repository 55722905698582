import * as React from "react"
import { Link } from "gatsby";
import * as navStyles from "./nav.module.css";

const Nav = () => {
    const [navOpen, setNavOpen] = React.useState(false);

    const navClass = `${navStyles.nav} ${navOpen ? navStyles.navOpen : ''}`;
    const hamburgerClass = `${navStyles.hamburger} ${navOpen ? navStyles.hamburgerOpen : ''}`;

    const navToggle = () => {
        if (navOpen) document.body.style.overflow = "visible"
        if (!navOpen) document.body.style.overflow = "hidden"
        setNavOpen(!navOpen);
    }
    return (
        <header>
            <div onClick={navToggle} className={hamburgerClass}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <nav className={navClass}>
                <div className={navStyles.navItem}>
                    <Link onClick={navToggle} to="/" className={navStyles.linkMobile} activeClassName={navStyles.linkActive}>Home</Link>
                    <Link to="/" className={navStyles.link} activeClassName={navStyles.linkActive}>Home</Link>
                </div>
                <div className={navStyles.navItem}>
                    <Link onClick={navToggle} to="/schedule" className={navStyles.linkMobile} activeClassName={navStyles.linkActive}>Info</Link>
                    <Link to="/schedule" className={navStyles.link} activeClassName={navStyles.linkActive}>Info</Link>
                </div>
                <div className={navStyles.navItem}>
                    <Link onClick={navToggle} to="/accommodation" className={navStyles.linkMobile} activeClassName={navStyles.linkActive}>Accommodation</Link>
                    <Link to="/accommodation" className={navStyles.link} activeClassName={navStyles.linkActive}>Accommodation</Link>
                </div>
                <div className={navStyles.navItem}>
                    <Link onClick={navToggle} to="/rsvp" className={navStyles.linkMobile} activeClassName={navStyles.linkActive}>RSVP</Link>
                    <Link to="/rsvp" className={navStyles.link} activeClassName={navStyles.linkActive}>RSVP</Link>
                </div>
            </nav>
        </header>
    )
};

export default Nav;
