import React from "react";
import * as layoutStyles from "./layout.module.css";
import Nav from "./nav.js";

const Layout = ({ children }) => (
    <main className={layoutStyles.main}>
        <Nav />
        {children}
    </main>
);

export default Layout;
